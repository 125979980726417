define('moment/index', ['exports', 'moment/lib'], function (exports, _momentLib) {
  'use strict';

  function compare(a, b) {
    if (_momentLib['default'].isMoment(a) && _momentLib['default'].isMoment(b)) {
      if (a.isBefore(b)) {
        return -1;
      } else if (a.isSame(b)) {
        return 0;
      } else {
        return 1;
      }
    }

    throw new Error('Arguments provided to `compare` are not moment objects');
  }

  _momentLib['default'].prototype.compare = compare;
  _momentLib['default'].compare = compare;

  _momentLib['default'].prototype.clone = function clone() {
    return (0, _momentLib['default'])(this);
  };

  exports['default'] = _momentLib['default'];
});