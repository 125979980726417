define('ember-moment/services/moment', ['exports', 'moment'], function (exports, _moment2) {
  'use strict';

  exports['default'] = Ember.Service.extend(Ember.Evented, {
    _timeZone: null,

    locale: null,
    localeOptions: null,
    defaultFormat: null,

    __config__: Ember.computed(function () {
      var config = Ember.getOwner(this).factoryFor('config:environment')['class'] || {};

      return Ember.get(config, 'moment') || {};
    }).readOnly(),

    timeZone: Ember.computed('_timeZone', {
      get: function get() {
        return Ember.get(this, '_timeZone');
      },
      set: function set(propertyKey, timeZone) {
        if (!_moment2['default'].tz) {
          /* eslint-disable no-console */
          console.warn('[ember-moment] attempted to set timezone, but moment-timezone is not setup.');
          return;
        }

        Ember.set(this, '_timeZone', timeZone);

        return timeZone;
      }
    }),

    setLocale: function setLocale(locale) {
      this.changeLocale(locale);
    },
    updateLocale: function updateLocale(locale) {
      var localeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this.changeLocale(locale, localeOptions);
    },
    changeLocale: function changeLocale(locale) {
      var localeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      Ember.setProperties(this, {
        locale: locale,
        localeOptions: localeOptions
      });
      _moment2['default'].updateLocale(locale, localeOptions);
      this.trigger('localeChanged', locale);
    },
    setTimeZone: function setTimeZone(timeZone) {
      this.changeTimeZone(timeZone);
    },
    changeTimeZone: function changeTimeZone(timeZone) {
      Ember.set(this, 'timeZone', timeZone);
      this.trigger('timeZoneChanged', timeZone);
    },
    isMoment: function isMoment(obj) {
      return _moment2['default'].isMoment(obj);
    },
    moment: function moment() {
      var momentObj = _moment2['default'].apply(undefined, arguments);

      var _EmberGetProperties = Ember.getProperties(this, 'locale', 'timeZone'),
          locale = _EmberGetProperties.locale,
          timeZone = _EmberGetProperties.timeZone;

      if (locale && momentObj.locale) {
        momentObj = momentObj.locale(locale);
      }

      if (timeZone && momentObj.tz) {
        momentObj = momentObj.tz(timeZone);
      }

      return momentObj;
    },
    utc: function utc() {
      var momentObj = _moment2['default'].utc.apply(_moment2['default'], arguments);

      var _EmberGetProperties2 = Ember.getProperties(this, 'locale'),
          locale = _EmberGetProperties2.locale;

      if (locale && momentObj.locale) {
        momentObj = momentObj.locale(locale);
      }

      return momentObj;
    }
  });
});