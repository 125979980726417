define("ember-macro-helpers/expand-property", ["exports"], function (exports) {
  "use strict";

  exports["default"] = function (property) {
    var newPropertyList = [];
    Ember.expandProperties(property, function (expandedProperties) {
      newPropertyList = newPropertyList.concat(expandedProperties);
    });
    return newPropertyList;
  };
});